<template>
  <div class="w1092">
    <div class="moneyread">
      <h3><!--img src="@/assets/img/mypage_point_waring.png"-->{{$t('front.cash.moneyCheckList')}}</h3>
      <ul>
        <li><!--img src="@/assets/img/icon_check.png" /-->{{$t('front.point.moneyPoint')}}</li>
        <li><!--img src="@/assets/img/icon_check.png" /-->{{$t('front.point.limitPoint')}}</li>
        <li><!--img src="@/assets/img/icon_check.png" /-->{{$t('front.cash.moneyCheckListD')}}</li>
      </ul>
    </div>

    <div class="moneyinfoappli">
      <div class="moneyinfo">
        <h3 class="applih">{{$t('front.point.changePoint')}}</h3>
        <p class="applip b0 pb0 mb0">{{$t('front.point.changePointA')}} <span>1,000</span>P {{$t('front.point.changePointB')}}</p>
      </div>

      <div class="appliinputwrap">
        <ul class="appliinput">
          <li>{{$t('front.point.leftPoint')}}</li>
          <li><span class="w260">{{thousand(currentPoint.toString())}}</span>P</li>
        </ul>
        <ul class="appliinput">
          <li>{{$t('front.point.expectPoint')}}</li>
          <li><span class="w260">{{changePoint}}</span>P</li>
        </ul>
        <ul class="appliinput">
          <li>{{$t('front.point.expectToPoint')}}</li>
          <li><span class="w260">{{thousand(remainPoint.toString())}}</span>P</li>
        </ul>
        <ul class="appliinput">
          <li>{{$t('front.point.pointInput')}}</li>
          <li><input type="text" pattern="[0-9.,]+" class="numb w260" :placeholder="$t('front.cash.inputNumber')" v-model="changePoint"></li>
        </ul>
        <ul class="moneybtnwrap ml150">
          <li class="one" @click="setPoint(10000)"><a>1{{$t('front.cash.manwon')}}</a></li>
          <li class="one" @click="setPoint(30000)"><a>3{{$t('front.cash.manwon')}}</a></li>
          <li class="one" @click="setPoint(50000)"><a>5{{$t('front.cash.manwon')}}</a></li>
          <li class="two" @click="setPoint(100000)"><a>10{{$t('front.cash.manwon')}}</a></li>
          <li class="two" @click="setPoint(300000)"><a>30{{$t('front.cash.manwon')}}</a></li>
          <li class="two" @click="setPoint(500000)"><a>50{{$t('front.cash.manwon')}}</a></li>
          <li class="four" @click="setPoint(0)"><a>{{$t('front.cash.correct')}}</a></li>
          <li class="thr" @click="setPoint('all')"><a>{{$t('front.cash.all')}}</a></li>
        </ul>
        <div class="applibtns">
          <a @click="onSubmit">{{$t('front.cash.moneyCheckListL')}}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="betlistmodal" v-if="isPopupOpen">
    <div class="betlist-content w600">
      <h4 class="betlist-title">{{$t('front.point.pointChangeApply')}}</h4>
      <div  class="coupongift">
        <div class="moneyread">
          <h3><img src="@/assets/img/mypage_point_waring.png">{{$t('front.cash.moneyCheckList')}}</h3>
          <ul>
            <li><em>✓</em> {{$t('front.point.moneyPoint')}}</li>
            <li><em>✓</em> {{$t('front.point.limitPoint')}}</li>
            <li><em>✓</em> {{$t('front.cash.moneyCheckListD')}}</li>
          </ul>
        </div>
        <p class="giftname mt20">{{$t('front.point.ablePoint')}}:<span class="bl b">278</span>P</p>
        <p class="giftpick">{{$t('front.point.transPoint')}}<input type="text" placeholder="0" /></p>
        <div class="pointbtn"><a class="blsbtn">{{$t('front.point.pointTrans')}}</a><a class="blksbtn">{{$t('front.stributor.detailList')}}</a></div>
      </div>

      <div class="betclose" @click="isPopupOpen = false"><img src="@/assets/img/wcloseicon.png" /></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { setChangePoint } from '@/api/point'
import { thousand } from '@/libs/utils'

export default {
  name: 'pointUse',
  watch: {
    changePoint () {
      const tempcgPoint1 = Number(this.currentPoint)
      const tempcgPoint2 = Number(this.changePoint.replace(/,/g, ''))
      if (tempcgPoint1 < tempcgPoint2) {
        this.onCheck('front.point.enoughChangePoint')
        this.changePoint = ''
        return false
      } else {
        this.remainPoint = this.currentPoint - Number(this.changePoint.replace(/,/g, ''))

        const parts = this.changePoint.split('.')
        const v = parts[0].replace(/\D/g, '')
        const dec = parts[1]
        const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
        // use this for numeric calculations
        console.log('number for calculations: ', calcNum)
        let n = new Intl.NumberFormat('en-EN').format(v)
        n = dec !== undefined ? n + '.' + dec : n
        this.changePoint = n
      }
    }
  },
  computed: {
    ...mapState([
      'userData'
    ])
  },
  created () {
    this.currentPoint = this.userData.pointAmt || 0
  },
  data () {
    return {
      isPopupOpen: false,
      currentPoint: '',
      changePoint: '',
      remainPoint: 0,
      limitMinPoint: 10000
    }
  },
  methods: {
    setPoint (value) {
      let point = Number(this.changePoint.replace(/,/g, ''))
      if (value === 'all') {
        point = this.currentPoint
      } else {
        if (value) {
          point += value
        } else {
          point = ''
        }
      }

      console.log(this.currentPoint)

      this.changePoint = thousand(point)
    },
    async onSubmit () {
      if (this.changePoint) {
        if (this.limitMinPoint > this.changePoint) {
          this.onCheck('front.point.limitPoint')
          return false
        }

        const confirm = await this.onConfirm('front.point.confirm')
        if (confirm) {
          const param = {
            pointAmt: Number(this.changePoint.replace(/,/g, ''))
          }

          setChangePoint(param).then(async response => {
            const result = response.data
            if (result.resultCode === '0') {
              const success = await this.onCheck('front.point.success')
              if (success) {
                location.reload()
              }
            }
          })
        }
      } else {
        await this.onCheck('front.point.check')
      }
    }
  }
}
</script>
<style scoped>
.moneyread {padding: 13px 20px;border-radius: 12px;border: 1px solid #2f3545;background-color: rgba(0, 0, 0, 0.25);font-weight: normal;text-align: left;}
.moneyread h3 {color: #fff;font-weight: bold;margin-bottom: 2px;font-size: 16px;}
.moneyread h3 img {height: 27px;vertical-align: middle;margin-right: 15px;}
.moneyread ul li {color: #fff;line-height: 1.5em;font-size: 14px;margin-top: 10px;}
.moneyread ul li img {margin: 0 11px 0 9px;}

.moneyinfoappli {border: 1px solid #2f3545; border-radius: 12px;padding: 25px 51px 27px;margin: 28px 0 75px;}
.moneyinfo {border-radius: 12px;border: 1px solid #2f3545;background-color: rgba(0, 0, 0, 0.25);padding: 20px;line-height: 1.5em;}
.applih {color: #fff;font-size: 16px;font-weight: normal;}
.applip {color: #fff;border-bottom: 1px solid #2f3545;padding: 10px 0 15px;margin-bottom: 15px;font-size: 14px;}
.applibtn {width: 109px;height: 34px;border-radius: 5px;background: linear-gradient( to bottom, #f5cc7f, #96653b, #b79453 );font-size: 14px;color: #fff;display: flex;justify-content: center;align-items: center;}
.applistep {color: #000;letter-spacing: -1px;font-weight: bold;margin-top: 10px}
.applistep li {counter-increment: li;margin-top: 5px;font-size: 14px;}
.applistep li em {margin-right: 10px;padding: 0px 8px;font-size: 10px;color: #000;}
.applistep strong {color: #000;}

.appliinputwrap {padding-top: 48px;}
.appliinputwrap>ul {margin-top: 50px;}
.appliinputwrap>ul:first-child {margin-top: 0;}
.appliinput {display: flex;align-items: center;gap: 36px;font-size: 16px;color: #fff;}
.appliinput li:first-child {width: 150px;font-weight: bold;position: relative;}
.appliinput li:first-child::after {content: '';width: 2px;height: 19px;background: #6b7aa3;position: absolute;right: 0;}
.appliinput li {width: calc(100% - 150px);}
.appliinput li span {display: inline-block;}
.appliselect {border-radius: 5px;border: 1px solid #4575b4;width: 133px;height: 28px;}
.numb {padding: 0;border: 0;background: none;width: 100%; color: #fff;font-size: 16px;}
.moneybtnwrap {display: flex;gap: 5px;margin-top: 26px !important;}
.moneybtnwrap a {display: inline-block;color: #fff;width: 98px;border-radius: 6px;text-align: center;padding: 8px 0;font-weight: bold;}
.moneybtnwrap .one a {background: #817358;}
.moneybtnwrap .two a {background: #bd8a2a ;}
.moneybtnwrap .thr a {background: #96653b;}
.moneybtnwrap .four a {background: #777;}
.moneybtnwrap .fiv {padding: 0;width: 130px;height: 34px;display: flex;justify-content: center;align-items: center;font-size: 14px;background: #fff;border: 1px solid #1c594e;color: #1c594e;}
.moneybtnwrap .fiv.on {background: #e1423c;border: 1px solid #4575b4;color: #fff;}
.applibtns {margin-top: 63px;display: flex;justify-content: center;gap: 78px;font-size: 16px;}
.applibtns a {background: linear-gradient( to bottom, #f5cc7f, #96653b, #b79453);border-radius: 10px;width: 193px;height: 48px;color: #fff;font-weight: bold;display: flex;align-items: center;justify-content: center;}

</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
